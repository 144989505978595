<template>
  <huboo-page class="dashboardContainer">
    <v-container>
      <v-row class="align-center">
        <v-col>
          <huboo-logo class="dashboardLogo"></huboo-logo>
          <p class="mt-4">
            What would you like to see here? Let us know via the slack channel
            <a href="https://hubootechnologies.slack.com/archives/C01EX635ACA" target="_blank">
              #product-management-feature-requests
            </a>
          </p>
          <p>Thank you - The Billing Team</p>
        </v-col>
      </v-row>
    </v-container>
  </huboo-page>
</template>

<script>
export default {
  name: 'Dashboard',
  data: () => ({}),
  mounted() {},
}
</script>

<style lang="scss" scoped>
.dashboardLogo {
  max-width: 600px;
  margin: auto;
}
</style>
